import { render, staticRenderFns } from "./modalAprobarAnuncio.vue?vue&type=template&id=b12b5186&scoped=true&"
import script from "./modalAprobarAnuncio.vue?vue&type=script&lang=js&"
export * from "./modalAprobarAnuncio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b12b5186",
  null
  
)

export default component.exports